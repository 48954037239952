.accreditation {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 10vh;
}

.photos {
    width: 16vw;
    height: auto;
}

.photos img {
    width: 16vw;
}

.gradient {
    display: inline-block;
	position: relative;    
	line-height: 0;
    cursor: pointer;
}

.gradient:after {
	content: "";
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}
.gradient:hover:after {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;    
	position: absolute;
	top: 0;    
	left: 0;   
	background: transparent;
	background-image: linear-gradient(to bottom, #cd2729, #db872d); 
	opacity: 0.7;
}

.gradient:before {
	content: "";
	opacity: 0;    
	transition: opacity 0.5s;
}

.gradient:hover:before {
	display: inline-block;
	width: 2vw;
	height: 2vw;
	position: absolute;
	top: 50%;
	left: 40%;    
	z-index: 100;  
	color: #fff;  
	text-align: center;
	font-size: 5vw;
	content: '+';
	opacity: 1;
}

.photos p {
    text-align: center;
    font-size: 1.2vw;

}

.backgroundOfImages {
    width: 100%;
    height: 100%;
    display: none;
}

.addition {
	margin-top: 5vh;
	margin-bottom: 7vh;
	margin-left: 2vw;
	font-size: 1.25vw;
}

.addition a {
	color: #cd2729 !important;
}

.addition a:hover {
	color: #000 !important;
}