.mainText {
    text-align: center;
    width: 60vw;
    padding-bottom: 5vh;
    margin-left: 20vw;
    margin-right: 20vw;
}

.aboutUs {
    font-size: 2.5vw;
    font-weight: bold;
}

.aboutUs::first-letter {
    color: #cd2729;
}

.text {
    font-size: 1.7vw;
}

.blockServices {
    width: 60vw;
    margin-left: 15vw;
    margin-right: 20vw;
    background: #ccc;
    padding-left: 5vw;
    padding-right: 5vw;
    padding-bottom: 5vh;
    padding-top: 5vh;
    margin-top: 2vh;
    margin-bottom: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.titleServices {
    text-align: center;
    font-size: 2.5vw;
    font-weight: bold;
}

.titleServices::first-letter {
    color: #cd2729;
}

.servicesFirstLine {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 1.5vw;
    margin-block-start: 7vh;
}

.servicesFirstLineLeft {
    text-align: right;
    height: auto;
    width: 25vw;
}

.servicesFirstLineRight {
    width: 30vw;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.listElem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-block-end: 4vh;
}

.listCircle {
    width: 0.5vw;
    height: 0.5vw;
    background: #cd2729;
    border: 1px solid #cd2729;
    border-radius: 100%;
    flex-shrink: 0;
}

.listText {
    margin-left: 2vw;
}

.servicesSecondLine {
    display: flex;
    justify-content: start;
    font-size: 1.5vw;
}

.servicesSecondLine p {
    width: 25vw;
    text-align: right;
}

.orderOfservicesTitle {
    text-align: center;
    font-size: 2.5vw;
    font-weight: bold;
}

.orderOfservicesTitle::first-letter {
    color: #cd2729;
}

.orderList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.5vw;
    margin-bottom: 10vh;
}

.orderList p {
    width: 15vw;
}

.orderList p::first-letter {
    color: #cd2729;
    font-size: 5vw;
    font-weight: bold;
}