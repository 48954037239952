.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 1s;
    z-index: 101;  
}

.modal.active {
    opacity: 1;
    pointer-events: all;
}

.modalContent {
    height: 100vh;
    transform: scale(0.5);
    transition: 1.5s all;
}

.modalContent.active {
    transform: scale(1);
}