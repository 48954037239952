.backgroundMenu {
    background-image: linear-gradient(to bottom, #fff 0%, rgba(0,0,0, 0.4) 75%), url(
        "../../assets/images/backMenu.jpg");
    width: 100%;
    background-size: cover;
}

.topMenu {
    display: flex;
    justify-content: space-between;
    padding: 1vw;
    padding-right: 2vw;
    padding-left: 2vw;
  }

.logo {
    width: 22vw;
    height: auto;
    z-index: 2;
}

.logo img {
    width: 22vw;
    height: auto;
}
 
.listOfPhones {
    width: auto;
    height: auto;
    list-style: none;
    text-align: right;
    font-size: 1.1vw;
    font-weight: bold;
    z-index: 2;
}

.quote {
    color: #fff;
    width: 70vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-evenly;
    margin-top: 10vh;
    margin-bottom: 20vh;
    z-index: 2;
}

#i1 {
    font-size: 3vw;
    margin-block-end: 0.2em;
}

#i2 {
    position: relative;
    left: 10vw;
    font-size: 3vw;
    margin-top: auto;
    margin-block-end: 0.8em;
}

#i3 {
    position: relative;
    left: 10vw;
    font-size: 1vw;
    margin-top: auto;
}

.menuBar {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
    font-size: 1.2vw;
}

.menuItem {
    width: 20vw;
    height: auto;
    color: #fff !important;
    text-align: center;
    background: #cd2729;
    padding-top: 1vw;
    padding-bottom: 1vw;
    cursor: pointer;
}

.menuItem:hover {
    color: #cd2729 !important;
    background: #fff;
}

.menuItemChosen {
    width: 20vw;
    height: auto;
    color: #cd2729 !important;
    text-align: center;
    background: #fff;
    cursor: pointer;
    padding-top: 1vw;
    padding-bottom: 1vw;
}
