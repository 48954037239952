.services {
    padding-top: 5vh;
    padding-bottom: 10vh;
    padding-left: 5vw;
    padding-right: 5vw;
}

.servicesTitle {
    text-align: center;
    font-size: 2.5vw;
    font-weight: bold;
}

.servicesTitle::first-letter {
    color: #cd2729;
}

.servicesTable {
    border-collapse: collapse;
    border: 3px solid #000;
    font-size: 1.5vw;
}

tr, td {
    border: 3px solid #000;
}

td {
    padding: 1vw;
}

.tableServicesFirstLine {
    font-size: 1.75vw;
    text-align: center;
}

.numbers {
    padding: 0.5vw;
    font-size: 1.2vw;
}

.numbersCol {
    text-align: center;
}

button {
    background: #cd2729;
    color: #fff;
    border: none;
    font-size: 1vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-right: 1vw;
    padding-left: 1vw;
    cursor: pointer;
}

button:hover {
    color: #cd2729;
    background: #fff;
}