@font-face {
  font-family: 'MyGothic';
  src: url('./assets/fonts/GOTHIC.TTF');
}

::-webkit-scrollbar{ 
  width: 0.5vw; /* 1 - вертикальный скроллбар */
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {
opacity: 0.5;
}

::-webkit-scrollbar-thumb {
  background: #cd2729; border-radius: 10px;
}

body {
  margin: 0;
  font-family: 'MyGothic';
  overflow-x: hidden !important;
}

a {
  text-decoration: none;
  color: #000 !important;
}
