@font-face {
    font-family: 'MyGothic';
    src: url('../../assets/fonts/GOTHIC.TTF');
}  

.footerBlack {
    width: 100%;
    background: linear-gradient(to left, #626262, #3b3b3b);
}

.footerGradient {
    width: 75%;
    background: linear-gradient(to left, #cd2729, #db872d);
    margin-left: 25%;
    padding-top: 2vh;
}

.footer {
    width: 100%;
    height: auto;
    background: #ccc;
    padding-bottom: 1vh;
    padding-top: 1vh;
}

.topFooter {
    display: flex;
    flex-direction: row;
}

.symbols {
    font-size: 1.5vw;
}

.contacts {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 20vw;
    margin-left: 2vw;
    margin-top: 0 !important;
    border-bottom: 1px solid black;
    padding-bottom: 2.5vh;
}

.phones {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 11vw;
    font-size: 1.1vw;
}

.phones img {
    width: 1vw;
    height: 2vh;
}

.phone2 {
    text-align: right;
    width: 11vw;
    margin-block-end: 0 !important;
    margin-block-start: 0 !important;
    font-size: 1.1vw;
}

.contTitle {
    font-size: 1.12vw;
}

.questText {
    width: 20vw;
    text-align: right;
    font-size: 1.1vw;
    margin-block-end: 5vh;
}

.questTitle {
    width: 20vw;
    text-align: right;
    font-size: 1.2vw;
    font-weight: bold;
    margin-block-end: 0;
}

.quest {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 2vw;
    border-bottom: 1px solid black;
    padding-bottom: 2.5vh;
}

.questBtn {
    background: #000;
    border: 0;
    color: #fff;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
    font-family: 'MyGothic';
    font-size: 1vw;
    cursor: pointer;
}

.questBtn:hover {
    background: #fff;
    color: #000;
}

.copyright {
    margin-left: 2vw;
    margin-top: 3.5vh;
    font-size: 1.2vw;
}