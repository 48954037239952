.firstLine {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.5vw;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.firstLine div {
    width: 25vw;
}

.firstLine div p {
    font-size: 2.5vw;
    font-weight: bold;
}

.firstLine div p::first-letter {
    color: #cd2729;
}

.secondLine {
    display: flex;
    justify-content: space-around;
    font-size: 1.5vw;
    padding-left: 10vw;
    padding-right: 10vw;
    margin-bottom: 10vh;
}

.title {
    text-align: center;
    font-size: 2.5vw;
    font-weight: bold;
}

.title::first-letter {
    color: #cd2729;
}